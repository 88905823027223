@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Oswald:wght@500&display=swap');

:root{
    --stc-red: #da291c;
    --stc-white: #fff;
    --stc-gray: #D3D3D3;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lato;
}
button,.card-header{
    font-family: Oswald !important;
}


html,body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#reader{
    max-width: 380px;
}


.App{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.manual_register{
    width: 90%;
    height: 80%;
    margin: 40px auto;
    border-radius: 10px;
    overflow: hidden;
}

.card-header{
    height: 60px;
    display: flex;
    align-items: center;
    color: var(--stc-white);
    justify-content: center;
    background-color: var(--stc-red);
}

.card-body{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: calc(100% - 60px);
    border: 1px solid lightgrey;
}

.manual__register-item input,
.manual__register-item select{
    text-indent: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.manual__register-item label{
    font-weight: 600;
    width: 100%;
}


button{
    width: 80%;
    height: 40px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    max-width: 280px;
    margin-top: 10px;
    border-radius: 5px;
    color: var(--stc-white);
    background-color: var(--stc-red);
}

button:disabled{
    background-color: rgba(218, 41, 28, 0.44);
}

.status{
    width: 50%;
    height: 40px;
    display: flex;
    margin: auto;
    border-radius: 10px;
    align-items: center;
    color: var(--stc-white);
    justify-content: center;
}

.status.success{
    background-color: green;
}

.status.danger{
    background-color: var(--stc-red);
}

.manual_register{
    display: none;
}


.card,.Scanner{
    width: 90%;
    height: 80vh;
    border-radius: 10px;
    margin-top: 20px;
    border: 1px solid var(--stc-gray);
}

.card-body{
    padding: 10px;
}

.card-body .item img{
    width: 200px;
    margin-bottom: 20px;
}

.item{
    background-color: transparent;
    -webkit-transition: 1s all ease;
    cursor: pointer;
}

.card .card-body .item:hover{
    background-color: rgba(211, 211, 211, 0.51);
}

.Scanner{
    display: none;
}

#reader{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#reader__scan_region{
    display: flex;
    justify-content: center;
    align-items: center;
}

#reader__scan_region video{
    height: 100% !important;
    width: 100%;
}

/*#html5-qrcode-anchor-scan-type-change{*/
/*    display: none !important;*/
/*}*/

#reader > div:first-child{
    display: none !important;
}

#reader__scan_region img{
    width: 100px;
}

#html5-qrcode-button-file-selection{
    font-size: 15px;
    width: 240px;
}

.item.result{
    padding: 10px;
}


.item.result button{
    display: none;
}
.item.result h1{
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-family: 'Oswald';
    border-bottom: 1px solid #D3D3D3;
    color: #da291c;
    margin-bottom: 20px;
}

.result-item-status.success,
.result-item-status.danger{
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    color: white;
    height: 40px;
    border-radius: 10px;
}
.result-item-status.danger{
    background-color: #da291c;
}

.result-item{
    width: 100%;
    border-bottom: 1px solid #D3D3D3;
    margin-bottom: 10px;
    padding: 10px 0 5px 0;
}

.result-item label{
    font-weight: bold;
    font-family: Lato;
    font-size: 15px;
    margin-bottom: 10px;
    display: flex;
}

.result-item .value{
    text-indent: 10px;
    font-family: Lato;
    font-size: 13px;
}

#html5-qrcode-button-camera-permission{
    font-size: 1rem !important;
}

.result-item-continuar{
    width: 90% !important;
    font-size: 18px;
}

/*horizontal*/
@media (orientation: landscape) {

    .manual__register-item:first-child{
        border-right: 1px solid lightgrey;
    }


    .manual__register-item{
        width: 50%;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .manual_register .item{
        width: 100%;
    }

    .manual_register .item  label{
        display: flex;
        margin-bottom: 10px;
    }
    .card-body .item{
        width: 50%;
        height: 100%;
        display: flex;
        font-size: 20px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .card-body .item:first-child{
        border-right: 1px solid #D3D3D3;
    }

    .manual__register-item.result .item{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid lightgrey;
    }

    .manual__register-item.result .item .value{
        width: 100%;
    }

    .searcher{
        width: 100%;
        height: fit-content;
    }

    .searcher input{
        margin-top: 10px;
    }

    .guest_list{
        width: 100%;
        margin-top: 20px;
        height: calc(100% - 65px);
        max-height: 800px;
        overflow: hidden;
        overflow-y: scroll;
    }

    table{
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 5px;
        border-bottom: none;
        border-right: none;
    }

    table td, table th{
        text-align: center;
        border-right: 1px  solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 10px;
    }


    table button{
        width: 100px;
        margin: 0;
        font-size: 14px;
    }

    .searcher select{
        background-color: white;
    }

}

@media (orientation: portrait) {

    .card-body{
        flex-direction: column;
    }

    .card-body .item{
        width: 100%;
        height: 50%;
        display: flex;
        font-size: 20px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid var(--stc-gray);
    }

    .item:last-child{
        border-bottom: none;
    }

    .card-body .item img{
        height: 80px;
        margin-bottom: 10px;
    }

    select{
        width: 80%;
        height: 40px;
        color: black;
        text-indent: 10px;
        margin-left: 10px;
        border-radius: 5px;
        margin-top: 10px;
        background-color: white;
        border: 1px solid var(--stc-gray);
    }

    select:disabled{
        background-color: var(--stc-gray);
    }

    .Scanner .item:first-child{
        height: 100%;
    }

    .result-item label{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .item.result{
        display: flex;
        position: absolute;
        bottom: -145%;
        box-shadow: 0px 0px 10px 10px rgba(0,0,0,.5);
        background-color: white;
        width: 90% !important;
        height: 500px !important;
        justify-content: flex-start !important;
        border-radius: 10px;
    }

    .Scanner .card-body{
        padding: 0;
        border-radius: 0 0 10px 10px;
    }

    #reader{
        border: none !important;
    }
}